<template>
  <view-item-setting>
    <template #operation>
      <en-button type="primary" @click="operation.export.click"> <i class="el-icon-upload2"></i> 导出</en-button>
    </template>

    <flex-box>
      <template #default="{ height }">
        <span style="margin-right: 48px">日期</span>
        <form-query>
          <en-form-item>
            <en-date-picker
              v-model:start="query.data.startDate"
              v-model:end="query.data.endDate"
              type="daterange"
              value-format="YYYY-MM-DD"
            ></en-date-picker>
          </en-form-item>
        </form-query>
        <en-scrollbar :native="false" :view-style="{ 'max-height': `${height}px` }">
          <div class="dashboard-content">
            <div v-for="m in form.formatData" :key="m.id" class="dashboard-content-count">
              <div v-for="n in m.info" :key="n.prop" :class="['dashboard-content-count-item', m.class]">
                <div style="margin-top: 32px; margin-left: 32px">
                  <div style="margin-bottom: 24px; display: flex; align-items: center; gap: 8px">
                    <span>{{ n.label }}</span>
                    <en-tooltip effect="dark" :content="n.description" placement="top">
                      <img src="../../assets/question.png" style="width: 16px; height: 16px" />
                    </en-tooltip>
                  </div>
                  <div style="display: flex; align-items: center; gap: 4px">
                    <span style="font-size: 32px">{{ form.data[n.prop] }}</span>
                    <span style="font-weight: 500">{{ n.unit }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </en-scrollbar>
      </template>
    </flex-box>
  </view-item-setting>
</template>

<script lang="ts">
const formatterDate = (date: any, fmt: any) => {
  let nowDate = {
    yyyy: date.getFullYear(),
    MM: date.getMonth() + 1,
    dd: date.getDate(),
    hh: date.getHours(),
    mm: date.getMinutes(),
    ss: date.getSeconds()
  }
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
  }
  for (var k in nowDate) {
    if (new RegExp('(' + k + ')').test(fmt)) {
      fmt = fmt.replace(RegExp.$1, RegExp.$1.length == 1 ? nowDate[k] : ('00' + nowDate[k]).substr(('' + nowDate[k]).length))
    }
  }
  return fmt
}
export default factory({
  config: {
    children: {
      operation: {
        children: {
          export: {
            ajax: {
              get: {
                action: 'GET /enospray/data/board/export',
                params(params) {
                  params.payload = this.query.data
                }
              }
            },
            async click() {
              await this.operation.export.get()
              this.store.openDownload()
            }
          }
        }
      },
      query: {
        data: {
          startDate: '',
          endDate: ''
        }
      },
      form: {
        is: 'form',
        formatData: [
          {
            id: 1,
            class: 'bg-blue',
            info: [
              {
                label: '汽修门店数',
                prop: 'cloudTenantCount',
                description: '已切换正常使用门店的数量',
                unit: '家'
              },
              {
                label: '汽修工单总数',
                prop: 'serviceCount',
                description: '已切换正常使用门店的总工单数量（已结算工单）',
                unit: '单'
              },
              {
                label: '周新增汽修工单数',
                prop: 'serviceWeekCount',
                description: '已切换正常使用门店近一周（近7天）新增的工单数量（已结算工单）',
                unit: '单'
              }
            ]
          },
          {
            id: 2,
            class: 'bg-green',
            info: [
              {
                label: '喷涂门店数',
                prop: 'sprayTenantCount',
                description: '已切换喷涂门店数量',
                unit: '家'
              },
              {
                label: '喷涂工单总数',
                prop: 'sprayWorkOrderCount',
                description: '已切换喷涂门店工单总数（已提交工单）',
                unit: '单'
              },
              {
                label: '周新增喷涂工单数',
                prop: 'sprayWorkOrderWeekCount',
                description: '已切换喷涂门店近一周（近7天）新增的工单数量（已提交工单）',
                unit: '单'
              }
            ]
          },
          {
            id: 3,
            class: 'bg-purple',

            info: [
              {
                label: '车管家门店数',
                prop: 'appTenantCount',
                description: '使用车管家app门店数',
                unit: '家'
              },
              {
                label: '车管家工单总数',
                prop: 'appServiceCount',
                description: '使用车管家门店工单总数',
                unit: '辆'
              }
            ]
          },
          {
            id: 4,
            class: 'bg-orange',
            info: [
              {
                label: 'crm门店总数',
                prop: 'crmTenantCount',
                description: '回访历史、服务历史、活动订单数据同时都大于0的门店数',
                unit: '家'
              },
              {
                label: 'crm周新增门店数',
                prop: 'crmTenantWeekCount',
                description: '近一周（近7天）回访历史、服务历史、活动订单数据同时都大于0门店数',
                unit: '家'
              },
              {
                label: '回访门店数',
                prop: 'serviceReturnVisitTenantCount',
                description: '回访历史数据大于0的门店数',
                unit: '家'
              },
              {
                label: '回访数',
                prop: 'serviceReturnVisitCount',
                description: '回访历史数据大于0的门店的回访历史数量',
                unit: '单'
              },
              {
                label: '服务门店数',
                prop: 'businessOpportunityTenantCount',
                description: '服务历史数据大于0的门店数',
                unit: '家'
              },
              {
                label: '服务数',
                prop: 'businessOpportunityCount',
                description: '服务历史数据大于0的门店的服务历史数量',
                unit: '单'
              },
              {
                label: '活动门店数',
                prop: 'mallComboOrderTenantCount',
                description: '活动订单数据大于0的门店数',
                unit: '单'
              },
              {
                label: '活动数',
                prop: 'mallComboOrderCount',
                description: '活动订单数据大于0的门店的活动订单数量',
                unit: '单'
              }
            ]
          },
          {
            id: 5,
            class: 'bg-yellow-green',
            info: [
              {
                label: '质保维修车辆数',
                prop: 'sprayQualityCount',
                description: 'enoch网点下已提交喷涂工单车牌号去重数',
                unit: '辆'
              },
              {
                label: '参保数',
                prop: 'sprayWarrantyCount',
                description: 'enoch网点下已提交喷涂工单质保状态为已激活的车牌号去重数',
                unit: '单'
              }
            ]
          },
          {
            id: 6,
            class: 'bg-red',
            info: [
              {
                label: '招商OA总计划',
                prop: 'oaCount',
                description: 'Z+A+B+C+D+E+F+达成',
                unit: '家'
              },
              {
                label: '招商OA周新增计划',
                prop: 'oaWeekCount',
                description: '近一周（近7天）新增（Z+A+B+C+D+E+F+达成）',
                unit: '家'
              },
              {
                label: '招商OA总意向',
                prop: 'oaECount',
                description: 'E',
                unit: '家'
              },
              {
                label: '招商OA周新增意向',
                prop: 'oaEWeekCount',
                description: '近一周（近7天）新增E',
                unit: '家'
              },
              {
                label: '招商OA总有效',
                prop: 'oaABCDCount',
                description: 'A+B+C+D',
                unit: '家'
              },
              {
                label: '招商OA周新增有效',
                prop: 'oaABCDWeekCount',
                description: '近一周（近7天）新增（A+B+C+D）',
                unit: '家'
              },
              {
                label: '招商OA总签约',
                prop: 'oaDealCount',
                description: '达成',
                unit: '家'
              },
              {
                label: '招商OA周新增签约',
                prop: 'oaDealWeekCount',
                description: '近一周（近7天）新增达成',
                unit: '家'
              }
            ]
          }
        ],
        ajax: {
          get: {
            action: 'GET /enospray/data/board',
            data: 'object',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = this.query.data
            }
          }
        }
      }
    }
  },

  mounted() {
    let oneDay = 24 * 60 * 60 * 1000 * 7
    let startDate = new Date(Date.now() - oneDay)
    let endDate = new Date(Date.now())
    startDate = formatterDate(startDate, 'yyyy-MM-dd')
    endDate = formatterDate(endDate, 'yyyy-MM-dd')
    this.query.data.endDate = endDate.toString()
    this.query.data.startDate = startDate.toString()
    this.form.get()
  }
})
</script>
<style scoped>
.dashboard {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  padding: 16px 24px 0 24px;
  box-sizing: border-box;
}

.dashboard-header {
  padding: 51px 225px;
  display: flex;
  background: #ffffff;
  justify-content: space-between;
}

.dashboard-content {
  padding: 0 40px;
  height: 100%;
  background: #ffffff;
  flex: 1;
  font-size: 20px;
}

.dashboard-content-header-content {
  display: flex;
  gap: 16px;
  margin-right: 32px;
}

.dashboard-content-count {
  margin-bottom: 48px;
  display: flex;
  flex-wrap: wrap;
  gap: 35px;
}

.dashboard-content-count-item {
  width: 442px;
  height: 148px;
  background-size: 100% 100%;
}

.bg-blue {
  background-image: url('../../assets/bg-blue.png');
}

.bg-green {
  background-image: url('../../assets/bg-green.png');
}

.bg-purple {
  background-image: url('../../assets/bg-purple.png');
}

.bg-orange {
  background-image: url('../../assets/bg-orange.png');
}

.bg-yellow-green {
  background-image: url('../../assets/bg-yellow-green.png');
}

.bg-red {
  background-image: url('../../assets/bg-red.png');
}

.dashboard-content-count-item-content {
  margin: 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
</style>
